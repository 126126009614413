import { DateTime, Info, WeekdayNumbers } from 'luxon'
import {
  Product,
  SelectModifier,
  MultiSelectModifier,
  ApiOpeningHour,
  OpeningHours,
  OpeningHourValue,
  ApiOrder,
} from './models/domain'

export const clone = (toBeCloned: Object) =>
  JSON.parse(JSON.stringify(toBeCloned))

export enum RoundingStrategy {
  Floor,
  Ceil,
  PlatformDefault,
}

export const round = (
  number: number,
  decimalPlaces: number = 2,
  roundingStrategy: RoundingStrategy = RoundingStrategy.PlatformDefault
) => {
  const multiplyFactor = Math.pow(10, decimalPlaces)
  const roundingFn =
    roundingStrategy === RoundingStrategy.Ceil
      ? Math.ceil
      : roundingStrategy === RoundingStrategy.Floor
      ? Math.floor
      : Math.round
  return roundingFn(multiplyFactor * number) / multiplyFactor
}

// Logic referenced from: https://www.vero.fi/en/businesses-and-corporations/taxes-and-charges/vat/how-to-calculate-vat/
export const getVatFromIncludingVatPrice = (includingVatPrice: number) =>
  (includingVatPrice * 24) / 124

export const calculateFinalPrice = (
  item: Product,
  overridingModifiers?: SelectModifier[],
  overridingMultiSelectModifiers?: MultiSelectModifier[]
) => {
  const modifiers = overridingModifiers ?? item.singleSelectModifiers
  const multiSelectModifiers =
    overridingMultiSelectModifiers ?? item.multiSelectModifiers
  return (
    item.basePrice +
    multiSelectModifiers
      .map((i) => i.selected)
      .reduce((a, b) => a.concat(b))
      .map((i) => i.price)
      .reduce((a, b) => a + b, 0) +
    modifiers.map((i) => i.selected.price).reduce((a, b) => a + b, 0)
  )
}

function findNextOpeningHour(
  openingHours: OpeningHours,
  startingTs: DateTime
): OpeningHourValue | undefined {
  let numberOfAttempts = 6 // try 6 other days
  let currentDayIdx = startingTs.weekday
  while (numberOfAttempts > 0) {
    const intermediateIdx = currentDayIdx + (1 % 7) // could be 0, need to pad
    currentDayIdx =
      intermediateIdx === 0 ? 1 : (intermediateIdx as WeekdayNumbers)
    const openingHourValue = openingHours[currentDayIdx]
    if (openingHourValue.isOpen) return openingHourValue

    numberOfAttempts--
  }

  return undefined
}

/**
 * Check if shop is open at the certain timestamp
 * If yes, provide the opening hour info of the current day
 * If not provide also info on when it will be open again
 * @param openingHours
 * @param current
 * @returns
 */
export const isOpen = (
  openingHours: OpeningHours,
  current: DateTime
): {
  open: boolean
  openingHour?: OpeningHourValue
  nextOpeningHour?: OpeningHourValue
} => {
  const currentDay = current.weekday
  const match = openingHours[currentDay]
  if (!match.isOpen)
    return {
      open: false,
      nextOpeningHour: findNextOpeningHour(openingHours, current),
    }

  const openTs = DateTime.now().set({
    hour: match.openTime!.hour,
    minute: match.openTime!.minute,
  })
  const closeTs = DateTime.now().set({
    hour: match.closeTime!.hour,
    minute: match.closeTime!.minute,
  })

  if (current < openTs) return { open: false, nextOpeningHour: match }
  if (current > closeTs)
    return {
      open: false,
      nextOpeningHour: findNextOpeningHour(openingHours, current),
    }

  return {
    open: true,
    openingHour: {
      day: currentDay,
      isOpen: true,
      openTime: openTs,
      closeTime: closeTs,
    },
  }
}

/**
 *
 * @param confirmed When the order was approved
 * @param closingTs When the shop is closing
 * @param processTimeMins Preparation time in minutes
 * @param lateBufferMins Buffer to prepration time in minutes
 */
export const getDeliveryEstimation = (
  confirmed: DateTime,
  closingTs: DateTime,
  qty: number = 1,
  processTimeMins: number = 30,
  lateBufferMins: number = 10
) => {
  const additionMinsPerDrink = 10
  const expectedEarliestTs = confirmed.plus({
    minutes: processTimeMins + qty * additionMinsPerDrink,
  })
  const expectedLatestTs = expectedEarliestTs.plus({
    minutes: lateBufferMins,
  })

  if (expectedEarliestTs > closingTs || expectedLatestTs > closingTs) {
    return closingTs.toFormat('HH:mm')
  }

  return `${expectedEarliestTs.toFormat('HH:mm')}-${expectedLatestTs.toFormat(
    'HH:mm'
  )}`
}

export const getLatestPickupTime = (
  readyTs: DateTime,
  closingTs: DateTime,
  maxWaitMins: number = 60
) => {
  let latestPickUpTs = readyTs.plus({ minutes: maxWaitMins })
  if (latestPickUpTs >= closingTs) {
    latestPickUpTs = closingTs
  }

  return latestPickUpTs.toFormat('HH:mm')
}

/**
 * Convert api's array of opening hours to key-value type
 * Fill in the days where data doesn't exist in db
 * @param apiOpeningHours
 * @returns
 */
export const getAllOpeningHours = (
  apiOpeningHours: ApiOpeningHour[]
): OpeningHours => {
  const openingHours = {} as OpeningHours

  for (let i = 1; i <= 7; i++) {
    const day = i as WeekdayNumbers
    const match = apiOpeningHours.find((aop) => aop.day === day)
    const openingHour: OpeningHourValue = match
      ? {
          day,
          isOpen: true,
          openTime: DateTime.fromFormat(match.openTime, 'HH:mm:ss'),
          closeTime: DateTime.fromFormat(match.closeTime, 'HH:mm:ss'),
        }
      : {
          day,
          isOpen: false,
        }

    openingHours[day] = openingHour
  }

  return openingHours
}

/**
 * Returns the string representation of the weekday
 * 1 -> Monday, 2 -> Tuesday,.... 7 -> Sunday
 * @param weekdayNumber
 * @returns
 */
export const getWeekdayLong = (weekdayNumber: WeekdayNumbers): string => {
  // Info.weekdays('long') array is 0 based idx where WeekdayNumber starts from 1
  const weekdayLongIndex = (weekdayNumber + 6) % 7
  return Info.weekdays('long')[weekdayLongIndex]
}

export function hasNewOrder(
  newIncomingOrders: ApiOrder[],
  incomingOrders: ApiOrder[]
) {
  const hasNew = newIncomingOrders.some((newIncomingOrder) => {
    const match = incomingOrders.find(
      (incomingOrder) => incomingOrder.id === newIncomingOrder.id
    )
    const notFound = !match
    return notFound
  })

  return hasNew
}

var newOrderSound = new Audio('./media/new_order.mp3')
export function makeNewOrderSound() {
  newOrderSound.play()
}
