import { Product } from '../models/domain'
import { calculateFinalPrice, round } from '../util'
import { Quantity } from './Quantity'

interface BasketItemProps {
  item: Product
}

export function BasketItem({ item }: BasketItemProps) {
  return (
    <div className="d-flex p-3 justify-content-between fs-4">
      <div className="d-flex">
        <Quantity
          quantity={item.qty!}
          setQuantity={() => {}}
          immutable={true}
        />
        <div className="basket-item-desc">
          <p className="item-name p-0 fw-bold m-0">{item.title}</p>
          <ul className="item-breakdown list-unstyled m-0 p-0">
            <li>Drink €{round(item.basePrice)}</li>
            {item.singleSelectModifiers.map((m) => (
              <li>
                {m.selected.label}{' '}
                {m.selected.price > 0 && `€${round(m.selected.price)}`}
              </li>
            ))}
            {item.multiSelectModifiers.map((m) => {
              const total = m.selected
                .map((o) => o.price)
                .reduce((a, b) => a + b, 0)
              return (
                <li>
                  {m.selected.map((s) => s.label).join(', ')}{' '}
                  {total > 0 && `€${round(total)}`}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="text-center center">
        €{round(calculateFinalPrice(item) * item.qty!)}
        <br></br>
      </div>
    </div>
  )
}
