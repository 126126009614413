import './custom.scss'
import './App.scss'
import { Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { ApiOrder, ApiOrderStatus } from './models/domain'
import { getOrders } from './services/oo-be'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IncomingOrder } from './components/IncomingOrder'
import { InProcessOrder } from './components/InProcessOrder'
import { ReadyOrder } from './components/ReadyOrder'
import UserNav from './components/auth0/UserNav'
import { useAuth0 } from '@auth0/auth0-react'
import { hasNewOrder, makeNewOrderSound } from './util'
import { DateTime } from 'luxon'

let ordersPollingInterval: NodeJS.Timer
let lastNewOrderSoundTs = DateTime.now()

type OrdersState = {
  incoming: ApiOrder[]
  inProcess: ApiOrder[]
  ready: ApiOrder[]
}

/**
 * Create a new set state action that contains logic to be applied
 * based on current and the new state, which is OrdersState. The logic:
 * - if has new incoming orders, play sound
 * - if not, if no incoming orders, do nothing
 * - if not, if there is still incoming orderes, play sound,
 *  depend on when sound was play last
 *
 * @param newOrders New state to be set at the end of the action
 * @returns a set state action which can be passed in to a set state fn
 */
function createSetOrderAction(newOrders: OrdersState) {
  const notifAfterIdleDurationSeconds = 60

  return (currentOrders: OrdersState) => {
    const hasNewIncoming = hasNewOrder(
      newOrders.incoming,
      currentOrders.incoming
    )
    if (hasNewIncoming) {
      makeNewOrderSound()
      lastNewOrderSoundTs = DateTime.now()
    } else {
      const hasIncoming = newOrders.incoming.length > 0
      if (hasIncoming) {
        const now = DateTime.now()
        const sinceLastNotifSeconds = now.diff(
          lastNewOrderSoundTs,
          'seconds'
        ).seconds
        if (sinceLastNotifSeconds > notifAfterIdleDurationSeconds) {
          makeNewOrderSound()
          lastNewOrderSoundTs = now
        }
      }
    }

    return newOrders
  }
}

function App() {
  const [orders, setOrders] = useState<OrdersState>({
    incoming: [],
    inProcess: [],
    ready: [],
  })
  const [visibility, setVisibility] = useState<
    Partial<{
      home: boolean
      incoming: boolean
      inProcess: boolean
      ready: boolean
    }>
  >({ home: true })
  const { getAccessTokenSilently } = useAuth0()

  const backToHomeIfEmpty = (newOrders: OrdersState) => {
    if (visibility.home) return
    if (
      (visibility.incoming && newOrders.incoming.length === 0) ||
      (visibility.inProcess && newOrders.inProcess.length === 0) ||
      (visibility.ready && newOrders.ready.length === 0)
    ) {
      setVisibility({ home: true })
    }
  }

  const initOrders = async () => {
    const token = await getAccessTokenSilently()
    const newApiOrders = await getOrders(token)

    const newOrders = {
      incoming: newApiOrders.filter((o) => o.status === ApiOrderStatus.CREATED),
      inProcess: newApiOrders.filter(
        (o) => o.status === ApiOrderStatus.CONFIRMED
      ),
      ready: newApiOrders.filter((o) => o.status === ApiOrderStatus.READY),
    }

    setOrders(createSetOrderAction(newOrders))
    backToHomeIfEmpty(newOrders)
  }

  useEffect(() => {
    initOrders()
    ordersPollingInterval && clearInterval(ordersPollingInterval)
    ordersPollingInterval = setInterval(initOrders, 10000)
  }, [])

  const incomingBgClass =
    orders.incoming.length > 0 ? 'btn-danger' : 'btn-warning'
  return (
    <>
      <div className="App">
        <UserNav></UserNav>
        <Button
          className="btn-secondary w-100 mb-3 fs-1"
          onClick={() => setVisibility({ home: true })}
        >
          {!visibility.home && ' Back to home '}
          <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
        </Button>
        {visibility.incoming && (
          <IncomingOrder
            orders={orders.incoming}
            postActionCb={() => initOrders()}
          ></IncomingOrder>
        )}
        {visibility.inProcess && (
          <InProcessOrder
            orders={orders.inProcess}
            postActionCb={() => initOrders()}
          ></InProcessOrder>
        )}
        {visibility.ready && (
          <ReadyOrder
            orders={orders.ready}
            postActionCb={() => initOrders()}
          ></ReadyOrder>
        )}
        {visibility.home && (
          <div className="buttons">
            <Button
              className={incomingBgClass + ' w-100 mb-3 fs-1 py-5'}
              onClick={() => setVisibility({ incoming: true })}
            >
              Incoming ({orders.incoming.length})
            </Button>
            <Button
              className="w-100 btn-primary mb-3 fs-1 py-5"
              onClick={() => setVisibility({ inProcess: true })}
            >
              In Process ({orders.inProcess.length})
            </Button>
            <Button
              className="w-100 btn-success fs-1 py-5"
              onClick={() => setVisibility({ ready: true })}
            >
              Ready ({orders.ready.length})
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default App
