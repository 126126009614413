import { PropsWithChildren } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { ConfirmationModalOptions } from '../models/domain'

export const ConfirmationModal = (opt: PropsWithChildren<ConfirmationModalOptions>) => (
  <Modal show={opt.show} centered>
    {opt.done && (
      <>
        <p className="p-3 fs-3 text-center">{opt.doneText}</p>
        <div className="text-center">
          <Button
            className="btn-secondary w-100 py-2 fs-3"
            onClick={() => opt.close!()}
          >
            Close
          </Button>
        </div>
      </>
    )}
    {!opt.done && opt.showSpinner && (
      <div className="p-3">
        <p className="text-center">Processing...</p>
        <div className="text-center">
          {[...Array(3)].map((i) => (
            <Spinner
              animation="grow"
              variant="success"
              role="status"
              className="spinner-glow me-2"
            ></Spinner>
          ))}
        </div>
      </div>
    )}
    {!opt.done && !opt.showSpinner && (
      <>
        <Modal.Body>
          <p className='fs-3'>{opt.question}</p>
          {opt.showRejectDropdown && opt.children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-primary w-100 py-2 mb-3 fs-3"
            onClick={() => opt.confirm!()}
          >
            Confirm
          </Button>
          <Button
            className="btn-secondary w-100 py-2 fs-3"
            onClick={() => opt.cancel!()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </>
    )}
  </Modal>
)
