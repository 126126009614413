import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { ApiOrder, ConfirmationModalOptions } from '../models/domain'
import { markAsReadyForPickUp } from '../services/oo-be'
import { ConfirmationModal } from './Modals'
import { OrderReview } from './OrderReview'

export function InProcessOrder({
  orders,
  postActionCb,
}: {
  orders: ApiOrder[]
  postActionCb: Function
}) {
  const [confirmationModalOpt, setConfirmationModalOpt] =
    useState<ConfirmationModalOptions>({})
  const { getAccessTokenSilently } = useAuth0()

  const onCloseClick = async () => {
    await postActionCb()
    setConfirmationModalOpt({})
  }

  const onAcceptClick = (order: ApiOrder) => {
    async function apiAcceptThenShowResult() {
      setConfirmationModalOpt({ show: true, showSpinner: true })
      await markAsReadyForPickUp(order.id, await getAccessTokenSilently())
      setConfirmationModalOpt({
        show: true,
        showSpinner: false,
        done: true,
        doneText: `Succesfully marked order #${order.orderNo} as READY-FOR-PICKUP`,
        close: onCloseClick,
      })
    }

    setConfirmationModalOpt({
      show: true,
      question: `Click CONFIRM to mark order #${order.orderNo} as READY-FOR-PICKUP`,
      confirm: apiAcceptThenShowResult,
      cancel: () => setConfirmationModalOpt({ show: false }),
    })
  }

  return (
    <>
      <h2 className="bg-warning text-center py-3">IN PROCESS ORDERS ({orders.length})</h2>
      <ConfirmationModal {...confirmationModalOpt}></ConfirmationModal>
      {orders.map((order) => {
        return (
          <OrderReview
            order={order}
            onAccept={onAcceptClick}
            acceptBtnText="MARK AS READY"
          ></OrderReview>
        )
      })}
    </>
  )
}
