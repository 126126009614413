import { Button } from 'react-bootstrap'
import { ApiOrder, Basket } from '../models/domain'
import { round } from '../util'
import { BasketItem } from './BasketItem'

export const OrderReview = ({
  order,
  onAccept,
  acceptBtnText,
  onReject,
}: {
  order: ApiOrder
  onAccept: (o: ApiOrder) => void
  acceptBtnText?: string
  onReject?: (o: ApiOrder) => void
}) => {
  const basket = JSON.parse(order.jsonContent) as Basket
  return (
    <div className="border-bottom pb-4 pt-2">
      <div className="row p-3">
        <div className="col">
          <p className="m-0 fs-3">
            #<span className="fw-bold fs-1">{order.orderNo}</span>
          </p>
          {order.name && <p className="m-0 fs-4">by {order.name}</p>}
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <Button
                className="btn-success w-100 py-2 fs-3"
                onClick={() => onAccept(order)}
              >
                {acceptBtnText ?? 'Accept'}
              </Button>
            </div>
            {onReject && (
              <div className="col" onClick={() => onReject(order)}>
                <Button className="btn-danger w-100 py-2 fs-3">Reject</Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="basket-items-wrapper">
        {basket.items.map((item) => (
          <BasketItem item={item}></BasketItem>
        ))}
      </div>
      <p className="text-end fs-3 m-0 pe-3 pt-2">
        Total: <span className="fw-bold">€{round(basket.total)}</span>
      </p>
    </div>
  )
}
