import { useAuth0 } from '@auth0/auth0-react'
import { useRef, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { ApiOrder, ConfirmationModalOptions } from '../models/domain'
import { confirmOrder, rejectOrder } from '../services/oo-be'
import { ConfirmationModal } from './Modals'
import { OrderReview } from './OrderReview'

export function IncomingOrder({
  orders,
  postActionCb,
}: {
  orders: ApiOrder[]
  postActionCb: Function
}) {
  const { getAccessTokenSilently } = useAuth0()

  const [confirmationModalOpt, setConfirmationModalOpt] =
    useState<ConfirmationModalOptions>({})

  const rejectionReasons = ['Out of stock', 'Staffs too tired']
  const [rejectReason, setRejectReason] = useState(rejectionReasons[0])
  const rejectReasonRef = useRef(rejectReason)

  const onCloseClick = async () => {
    await postActionCb()
    setConfirmationModalOpt({})
  }

  const onAcceptClick = (order: ApiOrder) => {
    async function apiAcceptThenShowResult() {
      setConfirmationModalOpt({ show: true, showSpinner: true })
      await confirmOrder(order.id, await getAccessTokenSilently())
      setConfirmationModalOpt({
        show: true,
        showSpinner: false,
        done: true,
        doneText: `Succesfully accepted order #${order.orderNo}`,
        close: onCloseClick,
      })
    }

    setConfirmationModalOpt({
      show: true,
      question: `Click CONFIRM to accept order #${order.orderNo}`,
      confirm: apiAcceptThenShowResult,
      cancel: () => setConfirmationModalOpt({ show: false }),
    })
  }

  const onRejectClicked = (order: ApiOrder) => {
    async function apiRejectThenShowResult() {
      setConfirmationModalOpt({ show: true, showSpinner: true })
      await rejectOrder(
        order.id,
        rejectReasonRef,
        await getAccessTokenSilently()
      )
      setConfirmationModalOpt({
        show: true,
        showSpinner: false,
        done: true,
        doneText: `Succesfully rejected order #${order.orderNo}`,
        close: onCloseClick,
      })
    }

    setConfirmationModalOpt({
      show: true,
      question: `Please SELECT a reason, then click CONFIRM to REJECT order #${order.orderNo}`,
      showRejectDropdown: true,
      confirm: apiRejectThenShowResult,
      cancel: () => setConfirmationModalOpt({ show: false }),
    })
  }

  const bgClass = orders.length > 0 ? 'bg-danger' : 'bg-warning'
  return (
    <>
      <h2 className={bgClass + ' text-center py-3'}>
        INCOMING ORDERS ({orders.length})
      </h2>
      <ConfirmationModal {...confirmationModalOpt}>
        <DropdownButton
          onSelect={(value) => {
            const newReason = value!.replace('#', '')
            rejectReasonRef.current = newReason
            setRejectReason(newReason)
          }}
          id="reject-reason-dropdown"
          title={rejectReason}
        >
          {rejectionReasons.map((reason) => (
            <Dropdown.Item href={'#' + reason}>{reason}</Dropdown.Item>
          ))}
        </DropdownButton>
      </ConfirmationModal>
      {orders.map((order) => {
        return (
          <OrderReview
            order={order}
            onAccept={onAcceptClick}
            onReject={onRejectClicked}
          ></OrderReview>
        )
      })}
    </>
  )
}
