import './UserNav.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Dropdown } from 'react-bootstrap'

const UserNav = () => {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0()

  if (isLoading) return null

  if (!isAuthenticated) {
    return (
      <div className="oo-user-nav d-flex flex-row-reverse p-1">
        <div className="oo-user-nav-icon p-2" role="button">
          <FontAwesomeIcon
            icon={faUserCircle}
            color="grey"
            size="2x"
            onClick={() => loginWithRedirect()}
          ></FontAwesomeIcon>
        </div>
      </div>
    )
  }

  const picUrl = user?.picture
  return (
    <div className="oo-user-nav d-flex flex-row-reverse p-3">
      <Dropdown>
        <Dropdown.Toggle className="oo-user-nav-toggle">
          <span className='text-secondary me-3'>Logged in as {user?.name}</span>
          {!!picUrl && (
            <img
              className="oo-user-nav-pic"
              src={picUrl}
              alt={user!.name}
            ></img>
          )}
          {!picUrl && (
            <FontAwesomeIcon
              icon={faUserCircle}
              color="white"
              size="2x"
            ></FontAwesomeIcon>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="oo-user-nav-dropdown">
          <Dropdown.Item>Logged in as {user!.name}</Dropdown.Item>
          <Dropdown.Item onClick={() => logout()}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default UserNav
