import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import {
  AUTH0_DOMAIN,
  REACT_APP_AUTH0_OO_BE_AUDIENCE,
  REACT_APP_AUTH0_OO_RESTAURANT_FRONT_CLIENTID,
} from './config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const AuthenticatedApp = withAuthenticationRequired(App)

root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_OO_RESTAURANT_FRONT_CLIENTID}
      redirectUri={window.location.origin}
      audience={REACT_APP_AUTH0_OO_BE_AUDIENCE}
    >
      <React.StrictMode>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={<AuthenticatedApp></AuthenticatedApp>}
            ></Route>
            <Route
              path="*"
              element={<Navigate to="/" />}
            ></Route>
          </Routes>
        </ScrollToTop>
      </React.StrictMode>
    </Auth0Provider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
